@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box-shadow-register {
  box-shadow: 9px 10px 23px -10px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 9px 10px 23px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 9px 10px 23px -10px rgba(0, 0, 0, 0.5);
}

.home-bg {
  height: calc(100vh - 94px);
}

.bg-color-card {
  background-color: #d6e4f8 !important;
}

.button-add-todo {
  background-color: #3f5793 !important;
  color: white !important;
}

.radius-card {
  border-radius: 8px !important;
}

.swal2-container {
  z-index: 9999;
}

.todo-card-list::-webkit-scrollbar {
  display: none;
}

.todo-card-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
